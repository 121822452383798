import { Title } from 'src/ui/typography/title/Title';
import { Card } from '@caprikorn/dynamo-ui';
import { Column } from 'src/ui/Column/Column';
import styles from './AuthContentTemplate.module.css';

interface IAuthContentTemplateProps {
  children: React.ReactNode;
  title: string;
}

export const AuthContentTemplate = ({ children, title }: IAuthContentTemplateProps) => (
  <Column
    gap='xl'
    alignItems='center'
  >
    <Title tag='h1'>
      {title}
    </Title>
    <Card className={styles.AuthContentTemplateCard}>
      <Column
        padding={['md']}
        alignItems='center'
        data-testid='test-box-content'
      >
        {children}
      </Column>
    </Card>
  </Column>
);
