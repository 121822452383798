import { useTheme } from '@emotion/react';
import { Row } from 'src/ui/row/Row';
import { SplitLine } from 'src/ui/splitLine/SplitLine';
import arrowRightPicto from '@caprikorn/dynamo-icons/arrowRightMd.svg';
import layerPicto from '@caprikorn/dynamo-icons/layers.svg';
import { TextButton } from 'src/ui/button/textButton/TextButton';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';
import { Spacer } from 'src/ui/spacer/Spacer';
import { InfoItem } from 'src/ui/infos/InfoItem';
import { IInfoItemProps } from 'src/ui/infos/InfoItem.types';
import { Card } from '@caprikorn/dynamo-ui';
import searchText from 'src/texts/current/searchText.json';
import { Typo } from 'src/ui/typography/Typo';
import { Column } from 'src/ui/Column/Column';
import styled from 'src/theme/ThemeProvider';
import styles from './SplitBox.module.css';

interface ISplitBoxProps {
  title: string;
  subtitle: string;
  itemList?: Array<IInfoItemProps>;
  onClick(): void;
}

const CustomInfoItem = styled(InfoItem)`
  max-width: 30%;
  > span {
    font-size: ${({ theme }) => theme.fontSizes.text.xs}px;
  };
`;

export const SplitBox = ({
  title,
  subtitle,
  itemList,
  onClick,
}: ISplitBoxProps) => {
  const theme = useTheme();

  return (
    <Card
      className={styles.SplitBoxCard}
      size='sm'
    >
      <Row alignItems='center' justifyContent='space-between'>
        <Row alignItems='center'>
          <ReactSvgWrapper
            src={layerPicto}
            color={theme.colors.text.primary}
            size={theme.sizes.icons.md}
          />
          <Spacer width='xs' />
          <Column>
            <Typo.Span size='lg' weight='semi-bold'>{title}</Typo.Span>
            <Typo.Span>{subtitle}</Typo.Span>
          </Column>
        </Row>
        <TextButton
          variant='primary'
          rightIcon={arrowRightPicto}
          onClick={onClick}
        >
          {searchText.button.detail}
        </TextButton>
      </Row>
      {itemList && itemList?.length > 0
        && (
        <>
          <SplitLine
            upDownSpacing='sm'
            color={theme.colors.foreground[700]}
            size={1}
          />
          <Row
            alignItems='flex-start'
            gap='sm'
            width='inherit'
            flexWrap='nowrap'
          >
            {itemList.map((item) => (
              <CustomInfoItem
                key={item.value}
                value={item.value}
                name={item.name}
                subtitle={item.subtitle}
                direction='column'
              />
            ))}
          </Row>
        </>
        )}
    </Card>
  );
};
