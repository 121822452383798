import { useMutation } from '@tanstack/react-query';
import { setLocalStorageData, localStorageKey } from 'src/libs/localStorage.utils';
import {
  changePassword,
  askPasswordResetLink,
  setInitialPassword,
  login,
} from './AuthClient';
import {
  IAuthResetPwdData,
  IAuthSetInitialPwdData,
} from './AuthTypes';
import { useGenerateQueryKey } from '../shared/useGenerateQueryKey';
import { authQueriesEnum } from './AuthKeys';

export const useLoginMutation = () => useMutation({
  mutationFn: ({ email, password }: { email: string, password: string }) => login(email, password),
  mutationKey: useGenerateQueryKey({ path: authQueriesEnum.LOGIN }),
  onMutate: (variables) => {
    // we set the email in local storage for potential first connection case
    setLocalStorageData(localStorageKey.email, variables.email);
  },
  onSuccess: (response) => {
    setLocalStorageData(localStorageKey.email, response.email);
    setLocalStorageData(localStorageKey.accessToken, response.accessToken);
    setLocalStorageData(localStorageKey.refreshToken, response.refreshToken);
  },
});

export const useResetPasswordMutation = () => useMutation({
  mutationFn: ({
    previousPassword,
    newPassword,
  }: IAuthResetPwdData) => changePassword(
    previousPassword,
    newPassword,
  ),
  retry: false,
  mutationKey: useGenerateQueryKey({ path: authQueriesEnum.RESET_PASSWORD }),
});

export const useAskPasswordResetLink = () => useMutation({
  mutationFn: ({ email }: { email: string }) => askPasswordResetLink(email),
  retry: false,
  mutationKey: useGenerateQueryKey({ path: authQueriesEnum.RESET_PASSWORD_LINK }),
});

export const useSetInitialPasswordMutation = (
  loginCallBackAction: ({ email, password }: { email: string, password: string }) => void,
) => useMutation({
  mutationFn: ({
    email,
    token,
    newPassword,
  }: IAuthSetInitialPwdData) => setInitialPassword(
    email,
    token,
    newPassword,
  ),
  retry: false,
  mutationKey: useGenerateQueryKey({ path: authQueriesEnum.RESET_INITIAL_PASSWORD }),
  onSuccess: (data, variables) => {
    const { email, newPassword } = variables;
    loginCallBackAction({ email, password: newPassword });
  },
});
