import { ICardProps } from 'src/ui/card/TitledCard/TitledCard';
import { Card } from '@caprikorn/dynamo-ui';
import { classNames } from '@caprikorn/dynamo-utils';
import styles from './DrawerContainer.module.css';

export interface IDrawerContainerProps extends ICardProps {
  children: React.ReactNode;
  dataTestId?: string;
  className?: string;
}

export const DrawerContainer = ({
  children,
  dataTestId = 'Drawer-container',
  className,
  ...props
}: IDrawerContainerProps) => (
  <Card
    data-testid={dataTestId}
    className={classNames(className, styles.DrawerContainerCard)}
    {...props}
  >
    {children}
  </Card>
);
