import React from 'react';

import { Card } from '@caprikorn/dynamo-ui';
import { Typo } from 'src/ui/typography/Typo';
import { WithSkeleton } from 'src/ui/skeleton/WithSkeleton';
import businessText from 'src/texts/current/businessText.json';
import { SemanticTypes } from 'src/domains/common/CommonTypes';

import { Column } from 'src/ui/Column/Column';
import { Row } from 'src/ui/row/Row';
import * as Styled from './TicketCard.styles';

export type ITickedCardProps = {
  className?: string;
  dataTestId?: string;
  title?: string;
  variant?: SemanticTypes;
  id?: string;
  date?: string;
  current?: boolean;
  status?: string;
  loading?: boolean;
  onClick?: () => void;
};

export const TicketCard = React.forwardRef<HTMLDivElement, ITickedCardProps>(({
  className,
  dataTestId,
  title,
  id,
  date,
  status,
  onClick = () => true,
  current,
  variant,
  loading = true,
}: ITickedCardProps, ref) => (
  <Styled.TicketWrapper ref={ref} onClick={onClick}>
    <Card
      className={className}
      variant={current ? 'success' : 'default'}
      data-testid={dataTestId ? `TicketCard-${dataTestId}` : 'TicketCard'}
    >
      <WithSkeleton show={!loading} numberOfLines={5}>
        <Row justifyContent='space-between'>
          <Column>
            <Typo.Paragraph>
              {`${businessText.ticketCard.id} ${id || businessText.ticketCard.notApplicable}`}
            </Typo.Paragraph>
            <Styled.CustomTitle tag='h5'>
              {title || businessText.ticketCard.notApplicable}
            </Styled.CustomTitle>
            <Typo.Paragraph>
              {`${businessText.ticketCard.createdAt} ${date || businessText.ticketCard.notApplicable}`}
            </Typo.Paragraph>
          </Column>
          <Styled.CustomChip
            label={status || businessText.ticketCard.notApplicable}
            variant={variant}
          />
        </Row>
      </WithSkeleton>
    </Card>
  </Styled.TicketWrapper>
));

TicketCard.displayName = 'TicketCard';
