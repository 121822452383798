import { Typo } from 'src/ui/typography/Typo';
import texts from 'src/texts/current/shieldPageText.json';
import { Card } from '@caprikorn/dynamo-ui';
import { replaceText } from 'src/libs/text.utils';
import { closingDateTariffShieldForm } from 'src/configuration/utils/feature_flag';
import { ShieldPossible } from './ShieldPossible';

type NoShieldTypes = 'notElegible' | 'notPossible' | 'possible';

interface IShieldNoCertificateSwitcher {
  noCertificateCases: NoShieldTypes;
}

export const ShieldNoCertificateSwitcher = ({
  noCertificateCases,
}: IShieldNoCertificateSwitcher) => (
  <Card>
    <Typo.Paragraph weight='bold' size='lg'>
      {replaceText(
        texts.noStatement[noCertificateCases],
        { replaceValues: { date: closingDateTariffShieldForm.toLocaleDateString() } },
      )}
    </Typo.Paragraph>
    {noCertificateCases === 'possible' && <ShieldPossible />}
  </Card>
);
