/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import '@caprikorn/dynamo-ui/lib/style.css';
import { CacheProvider, Global } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import createCache from '@emotion/cache';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ThemeProvider } from 'src/theme/ThemeProvider';
import { AppRouter } from 'src/router/AppRouter';
import { DynamoContextProvider } from '@caprikorn/dynamo-ui';

import theme from './theme';
import GlobalCSSTheme from './theme/current/globalCSSTheme';
import queryClient from './AppQueryClient';
import {
  switchThemeCSS,
  getInitialThemeState,
  getDefaultThemeValue,
} from './theme/theme.utils';
import { isTestEnv } from './configuration/utils/constant';
import { TThemeVariant } from './theme/theme.types';
import { ThemeContext } from './theme/ThemeContext';
import './datadog.conf';
import { GROUP_ID_LENGTH } from './domains/shared/constants';
import { getLocalStorageData } from './libs/localStorage.utils';
import { axiosInstance } from './libs/requests';
import configuration from './configuration';
import '@caprikorn/dynamo-ui/theme/ekwateur/semantic.css';

const myCache = createCache({
  key: 'cache-provider-key',
});

const router = createBrowserRouter([
  { path: '*', Component: AppRouter },
]);

if (configuration.TENANT_NAME === 'ekwateur') {
  import('@caprikorn/dynamo-ui/theme/ekwateur/base.css');
} else if (configuration.TENANT_NAME === 'neutral') {
  // as Dynamo is providing ekwateur base.css, neutral is let here for future use.
  import('./theme/neutral/base.css');
}

function App(): React.ReactElement {
  const [themeVariant, setThemeVariant] = useState<TThemeVariant>(getInitialThemeState);

  // only to ensure group is correctly set on header when F5.
  const currentGroup = getLocalStorageData('currentGroupRef');
  if (currentGroup.length === GROUP_ID_LENGTH) {
    axiosInstance.defaults.headers.common['x-group-reference'] = currentGroup;
  }

  const switchTheme = useCallback((variant: TThemeVariant | undefined) => {
    const variantValue = variant ?? getDefaultThemeValue();
    setThemeVariant(variantValue);
    switchThemeCSS(variantValue);
  }, []);

  // TODO : check useMemo necessity
  const appContextValues = useMemo(() => ({
    switchTheme,
    themeVariant,
  }), [switchTheme, themeVariant]);

  return (
    <CacheProvider value={myCache}>
      <DynamoContextProvider>
        <Global styles={GlobalCSSTheme} />
        <ThemeProvider theme={{ ...theme, colors: theme.colors(themeVariant) }}>
          <QueryClientProvider client={queryClient}>
            {isTestEnv && <ReactQueryDevtools initialIsOpen={false} />}
            <ThemeContext.Provider value={appContextValues}>
              <RouterProvider router={router} />
            </ThemeContext.Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </DynamoContextProvider>
    </CacheProvider>
  );
}

export default App;
