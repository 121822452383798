import React, { useEffect, lazy } from 'react';
import {
  Route,
  Navigate,
  useLocation,
  Routes,
} from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { VerticalBackgroundLayout } from 'src/components/layouts/page/VerticalBackgroundLayout';
import { loginRoutes, routes } from 'src/router/RouteConstants';

import { AuthLoginContentProvider } from 'src/pages/auth/form/login/AuthLoginFormProvider';
import authText from 'src/texts/current/authPageText.json';
import { AuthContentTemplate } from './templates/content/AuthContentTemplate';

const AuthResetRouter = lazy(() => import('src/pages/auth/form/reset/AuthResetRouter'));
const AuthConfirmFormWithModal = lazy(() => import('src/pages/auth/form/confirm/AuthConfirmFormWithModal'));

export function AuthPage(): React.ReactElement {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  useEffect(() => {
    queryClient.clear();
  }, []);

  const title = pathname === routes.AUTH_RESET ? authText.page.forgotPwd : authText.page.welcome;

  return (
    <VerticalBackgroundLayout>
      <AuthContentTemplate title={title}>
        <Routes>
          <Route index element={<AuthLoginContentProvider />} />
          <Route
            path={loginRoutes.AUTH_FIRST_CONNECTION}
            element={<AuthConfirmFormWithModal />}
          />
          <Route path={loginRoutes.AUTH_RESET_ROUTER} element={<AuthResetRouter />} />
          <Route
            path={loginRoutes.AUTH_RESET_CONFIRM_NEW_PASSWORD}
            element={<AuthConfirmFormWithModal />}
          />
          <Route path='*' element={<Navigate to={loginRoutes.AUTH_LOGIN} />} />
        </Routes>
      </AuthContentTemplate>
    </VerticalBackgroundLayout>
  );
}
